<script lang="ts" setup>
import { useStorage, useTimeoutFn } from '@vueuse/core'

const auth = useAuth()
const client = useClient()

// need to keep the step in sync to the browser
const tourStep = useStorage<number | null>('forgd:homepage:tour', null, undefined, {
  // need to force number literals
  serializer: {
    read: (v: any) => v === null ? null : Number.parseFloat(v),
    write: (v: any) => Number(v || 0).toString(),
  },
})

const TOTAL_TOUR_STEPS = 3

// we need to disable the tour permanently for the user once they close it or finish
const stopIsGuidedTourWatcher = watch(tourStep, (val) => {
  if (val > TOTAL_TOUR_STEPS) {
    client.users.updateUser({
      body: {
        isGuidedTourEnabled: false,
      },
    })
    stopIsGuidedTourWatcher()
  }
})

onMounted(() => {
  useTimeoutFn(() => {
    // avoid a flash of the tooltip from server response
    if (tourStep.value === null && auth.me.isGuidedTourEnabled) {
      tourStep.value = 0
    }
  }, 2000)
})

const { project } = storeToRefs(auth)

const tabs = [
  { label: 'Playbook', to: '/playbook' },
  // DEV-1310
  // { label: 'Tools & services', to: '/tools' },
  { label: 'Token Settings', to: '/token-settings' },
]

const route = useRoute()
const tab = computed(() => {
  return tabs.findIndex(t => t.to === route.path)
})

const tgeDate = computed(() => {
  return formatValue(project.value?.tgeDate, 'date')
})

const daysUntilTge = computed(() => {
  if (!project.value?.tgeDate) {
    return 0
  }
  const date = new Date(project.value?.tgeDate)
  const now = new Date()
  return Math.floor((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
})

const { data: coingeckoData } = useAsyncData(() => $fetch('/reference/projects', {
  query: {
    ids: project.value?.coingeckoId || project.value?.ticker,
  },
}))

const price = computed(() => {
  if (!coingeckoData.value) {
    return null
  }
  // find based on ticker and coingeckoId
  return coingeckoData.value?.find((p) => {
    return p.id === project.value?.coingeckoId || p.symbol.toLowerCase() === project.value?.ticker?.toLowerCase()
  })?.price || 'n/a'
})
</script>

<template>
  <div data-layout="core/home">
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex gap-4 items-center">
          <img src="/app/illustrations/dashboard-alt.svg" alt="Dashboard Icon" width="32" height="32">
          <div class="text-xl font-semibold flex-shrink-0">
            Welcome to Forgd
          </div>
        </div>
      </template>
      <div>
        <UiAlert
          v-if="project?.isResearch"
          title="You are visiting Forgd as a researcher. You can explore activities and tools using our demo token FRGD."
          class="mb-8"
          inline
        />

        <div class="lg:flex items-start justify-between mb-12 lg:space-y-0 space-y-5">
          <div class="max-w-2xl">
            <div class="text-xl text-forgd-primary-900 font-semibold mb-5">
              <template v-if="project?.tokenListed">
                Optimize Post-TGE Growth for {{ project?.ticker?.toUpperCase() || project?.name }}
              </template>
              <template v-else>
                Prepare for {{ project?.ticker?.toUpperCase() || project?.name }}’s Launch from your pre-TGE Dashboard
              </template>
            </div>
            <div class="text-forgd-gray-600 text-sm mb-5">
              <template v-if="project?.tokenListed">
                Monitor {{ project?.ticker?.toUpperCase() || project?.name }}’s performance and refine your growth strategy with our suite of tools. Leverage actionable insights to sustain your market presence & catalyze protocol growth.
              </template>
              <template v-else>
                Streamline your path to a successful TGE with essential tools and data. Use this dashboard to plan, execute, and manage pre-launch activities seamlessly.
              </template>
            </div>
            <UiBaseLayer v-if="project?.baseLayer" :layers="project.baseLayer" />
          </div>
          <div class="grid gap-2 max-w-lg grid-cols-2">
            <UiTooltipTour v-model="tourStep" :step="2" :total-steps="TOTAL_TOUR_STEPS" class="relative inline" placement="left">
              <div class="w-[371px] ">
                <div class="text-base font-semibold mb-3">
                  Explore the status of your project
                </div>
                <div class="text-sm text-forgd-gray-400 mb-3">
                  Toggle the status of your token between pre-TGE and listed to explore recommended activities, and available tools. Manage it from the Token Settings page.
                </div>
              </div>
              <template #target>
                <UiStat variant="card" title="Token Status" align="center">
                  <UiBadge size="sm" color="green" :label="project?.tokenListed ? 'Listed' : 'Pre-TGE'" />
                </UiStat>
              </template>
            </UiTooltipTour>

            <UiStat
              variant="card"
              title="TGE Date"
              align="center"
              :value="tgeDate"
            />

            <UiStat v-if="project?.tokenListed" variant="card" align="center" title="Price (USD)">
              <template #value>
                <UiOptional v-slot="{ value }" :value="price">
                  <template v-if="value !== 'n/a'">
                    <UiFormat size="md" format="currency:short,3" :value="price" class="text-primary-900" />
                  </template>
                  <template v-else>
                    <UiLabel text="N/A" tooltip="To display price a link to a CoinGecko coin page is needed. If you already have your token listed in CoinGecko update it in the token settings page." />
                  </template>
                </UiOptional>
              </template>
            </UiStat>

            <UiStat
              variant="card"
              :title="daysUntilTge < 0 ? 'Days Since TGE' : 'Days Until TGE'"
              align="center"
              :value="Math.abs(daysUntilTge)"
            />

            <UiTooltipTour v-model="tourStep" :step="3" :total-steps="TOTAL_TOUR_STEPS" class="relative inline" placement="left">
              <div class="w-[371px] ">
                <div class="text-base font-semibold mb-3">
                  Token teammates
                </div>
                <div class="text-sm text-forgd-gray-400 mb-3">
                  Admins of projects have the ability to send invitation to new potential teammates. Manage them from the Token Settings page.
                </div>
              </div>
              <template #target>
                <UiStat
                  variant="card"
                  title="Active Teammates"
                  align="center"
                  :value="auth.organization?.membersCount"
                />
              </template>
            </UiTooltipTour>
          </div>
        </div>
        <div class="relative">
          <UiTooltipTour v-model="tourStep" :step="1" :total-steps="TOTAL_TOUR_STEPS" class="relative inline" placement="right" :offset="120">
            <div class="w-[371px] ">
              <div class="text-base font-semibold mb-3">
                Explore recommended activities and tools
              </div>
              <div class="text-sm text-forgd-gray-400 mb-3">
                Learn about our recommended playbook based on the status of your token, and explore tools that will help you achieve your goals.
              </div>
            </div>
          </UiTooltipTour>
        </div>
        <UiTabs :model-value="tab" :items="tabs" />
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
